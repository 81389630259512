<template>
  <div class="page-search">
    <common-header class="header" v-show="true" />
    <section class="search-main">
      <div class="search-head">
        <img src="@/assets/img/common/common_add_gray.png" style="transform: rotate(45deg)" alt="" class="icon close-icon" @click="close">
        <div class="wrapper">
          <div class="search-input">
            <input type="text" v-model="keyword" @keyup.enter="search">
            <img class="icon search-icon" src="@/assets/img/common/search-orange-icon.png" alt="">
            <span class="search-btn" @click="search">
              搜索
              <img class="icon to-icon" src="@/assets/img/common/right-arrow-icon.png" alt="">
            </span>
          </div>
        </div>
      </div>
      <div class="search-body">
        <div class="wrapper" v-show="isSearch">
          <div class="result-title" v-if="total">找到关于“{{finalKeyword}}”的{{total}}个搜索结果</div>
          <div class="result-title" v-else>未找到“{{finalKeyword}}”的相关搜索结果</div>
          <div class="result-list">
            <ul>
              <li v-for="item in records" :key="item.id">
                <router-link :to="{path:'/aboutUs/detail',query:{id:item.id,type: item.type == 1 ? 'news' : item.type == 2 ? 'responsibility' : item.type == 3 ? 'media' : 'news'}}">
                  <div class="title" v-html="item.name"></div>
                  <div class="date">{{item.disploy_at.substring(0, 10)}}</div>
                  <div class="brief text-overflow-clamp">
                    {{item.discribe}}
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <pager :total="total" :page="page" :limit="limit" @current-change="handleCurrentChange"></pager>
        </div>
      </div>
    </section>
    <common-footer />
    <back-to-top></back-to-top>
  </div>
</template>
<script>
import CommonHeader from '@/components/common/Header.vue'
import CommonFooter from '@/components/common/Footer.vue'
import BackToTop from '@/components/common/BackToTop.vue'
import Pager from '@/components/common/Pager.vue'
import { getNewsAndDutyData } from '@/api/aboutUs'
import { EventBus } from '@/helper/eventBus'
import { isMobile } from '@/helper/utils'
export default {
  name: 'searchIndex',
  components: {
    CommonHeader,
    CommonFooter,
    BackToTop,
    Pager
  },
  data() {
    return {
      keyword: '',
      total: 0,
      page: 1,
      limit: 6,
      records: [],
      isSearch: false,
      finalKeyword: ''
    }
  },
  filters: {
    dateFormat(val) {
      console.log(val)
      let d = new Date(val)
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      month = month < 0 ? '0' + month : month
      let day = d.getDate()
      d = day < 0 ? '0' + day : day
      return year + ',' + month + ' ,' + day
    }
  },
  created() {
    EventBus.$emit('close-loading')
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword
      this.search()
    }
  },
  beforeCreate() {
    if (isMobile()) {
      document.querySelector('html').style.fontSize = '71px'
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === '/') {
      var docEI = document.documentElement;
      var clientWidth = docEI.clientWidth
      docEI.style.fontSize = 100 * (clientWidth / 1920) + 'px'
    }
    next();
  },
  mounted() {
    document.getElementById('app').style.paddingTop = '0.85rem'
  },
  methods: {
    async getData() {
      const res = await getNewsAndDutyData({
        page: this.page,
        limit: this.limit,
        keyword: this.keyword
      })
      this.isSearch = true
      this.finalKeyword = this.keyword
      let records = res.records || []
      let reg = new RegExp(this.keyword, 'g')
      let str = '<span class="keyword">' + this.keyword + '</span>';
      records.forEach(item => {
        let name = (item.name || '').replace(reg, str)
        item.name = name
      })
      this.records = records
      console.log(this.records)
      this.total = res.total
    },
    close() {
      this.$router.back()
    },
    search() {
      if (!this.keyword) {
        this.$message({
          type: 'warning',
          message: '请输入关键词',
          offset: 50
        })
        return
      }
      this.page = 1
      this.getData()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getData()
    }
  }
}
</script>
<style scoped lang="scss">
ul,
li,
input {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
input {
  border: none;
  appearance: none;
  outline: none;
  background: none;
}
.page-search {
  .header {
    display: block !important;
    ::v-deep header {
      display: block !important;
    }
  }
  .search-main {
    min-height: 80vh;
  }
  .search-head {
    position: relative;
    height: pxToRem(240);
    padding-top: pxToRem(90);
    background: #f8f8f8;
    .wrapper {
      max-width: pxToRem(1400);
      margin: 0 auto;
    }
    .close-icon {
      position: absolute;
      right: pxToRem(41);
      top: pxToRem(38);
      width: pxToRem(19);
      cursor: pointer;
    }
    .search-input {
      position: relative;
      .search-icon {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: pxToRem(-11);
        width: pxToRem(22);
      }
      .search-btn {
        position: absolute;
        right: 0;
        top: 0;
        height: pxToRem(76);
        line-height: pxToRem(76);
        font-size: pxToRem(14);
        color: #999999;
        cursor: pointer;
        .icon {
          width: pxToRem(15);
          height: pxToRem(15);
          margin-left: pxToRem(9);
          vertical-align: middle;
        }
      }
      input {
        width: 100%;
        height: pxToRem(76);
        line-height: pxToRem(28);
        padding: pxToRem(24) pxToRem(54) pxToRem(24) pxToRem(60);
        font-size: pxToRem(30);
        border-bottom: 1px solid #bcbdbf;
        color: #999999;
        &:focus {
          border-color: $orange;
        }
      }
    }
  }
  .search-body {
    padding-top: pxToRem(60);
    padding-bottom: pxToRem(90);
    .wrapper {
      max-width: pxToRem(1400);
      margin: 0 auto;
    }
    .result-title {
      position: relative;
      padding: pxToRem(28) 0;
      font-size: pxToRem(18);
      border-bottom: 1px solid #dcdcdc;
      &:after {
        content: "";
        position: absolute;
        bottom: pxToRem(-2);
        left: 0;
        width: pxToRem(124);
        height: pxToRem(4);
        min-height: 2px;
        border-radius: pxToRem(2);
        background: $orange;
      }
    }
    .result-list {
      //padding-bottom: pxToRem(80);
      li {
        padding: pxToRem(42) 0;
        font-size: pxToRem(16);
        border-bottom: 1px solid rgba(#7d7d7d, 0.2);
        a {
          text-decoration: none;
          color: #333;
          &:hover {
            color: $orange;
          }
        }
        ::v-deep .keyword {
          color: $orange;
        }
        .title {
          //padding-top: pxToRem(7);
          font-size: pxToRem(22);
        }
        .date {
          margin-top: pxToRem(15);
          color: #b8b7b7;
        }
        .brief {
          margin-top: pxToRem(9);
          line-height: pxToRem(30);
          color: #666666;
        }
      }
    }
  }
}
.text-overflow-clamp {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3; /*限制在一个块元素显示的文本的行数*/
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (max-width: $moble_width) {
  .page-search {
    .search-head {
      .wrapper {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
    .search-body {
      padding-top: 0.3rem;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
</style>