<template>
    <transition name="fadeIn" enter-active-class="fadeInUp">
      <div class="back-to-top" @click="backToTop" v-show="showBack"></div>
    </transition>
</template>
<script>
import { isMobile } from '@/helper/utils'
export default {
  data() {
    return {
      isMobile: isMobile(),
      timer: null,
      showBack: false
    }
  },
  methods: {
    backToTop() {
      window.scrollTo(0, 0)
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      if (this.timer) {
        //清空timer
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        const top =
          document.documentElement.scrollTop || document.body.scrollTop
        const clientHeight = document.documentElement.clientHeight
        if (top > clientHeight) {
          this.showBack = true
        } else {
          this.showBack = false
        }
      }, 100)
    })
  }
}
</script>
<style lang="scss" scoped>
.back-to-top {
  width: 0.97rem;
  height: 0.97rem;
  background-size: 100% 100%;
  background-image: url("../../assets/img/common/back-top-top.png");
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  right: 0.23rem;
  z-index: 99999;
  transition: all 0.8s;

  &:hover {
    transform: scale(1.2);
  }
}
</style>